import React, { useState } from "react";
import Button from "src/component/common/button";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { ImgixGatsbyImage } from "@imgix/gatsby";
import { useStaticQuery, graphql } from "gatsby";
import Loader from "src/component/common/loader";

export default function InterviewPostList() {
  const data = useStaticQuery(graphql`
    query {
      allMicrocmsResources(
        sort: { fields: display_date, order: DESC }
        filter: { post_category: { elemMatch: { id: { eq: "interview" } } } }
      ) {
        edges {
          node {
            id
            resourcesId
            title
            company_name
            display_date(formatString: "YYYY-MM-DD")
            resources_thumbnail {
              url
              width
              height
            }
            post_category {
              id
              name
            }
            type_category {
              id
              name
            }
            uses_category {
              id
              name
            }
            ai_services_category {
              id
              name
            }
            contents
          }
        }
      }
    }
  `);

  const allArticles = data.allMicrocmsResources.edges;
  const [visibleCount, setVisibleCount] = useState(9);
  const [isLoading, setIsLoading] = useState(false); 
  const INCREMENT = 9;

  const loadMore = () => {
    setIsLoading(true); // ローディング開始
    setTimeout(() => {
      setVisibleCount((prev) => prev + INCREMENT);
      setIsLoading(false); // ローディング終了
    }, 1000); // 1秒後に記事を表示（APIの遅延を模擬）
  };

  const convertId = (id) => id.replace(/_/g, "-");

  return (
    <>
      <ol className="resouses-articles-list">
        {allArticles.slice(0, visibleCount).map(({ node }) => (
          <li key={node.id}>
            <Link to={`/interview/${convertId(node.resourcesId)}`}>
              {node.resources_thumbnail?.url ? (
                <div className="resouses-articles-list__image">
                  <ImgixGatsbyImage
                    src={node.resources_thumbnail.url}
                    imgixParams={{ auto: ["format", "compress"] }}
                    layout="fullWidth"
                    loading="lazy"
                    style={{ height: "100%" }}
                    alt={node.title}
                    sourceWidth={node.resources_thumbnail.width}
                    sourceHeight={node.resources_thumbnail.height}
                  />
                </div>
              ) : (
                <div className="resouses-articles-list__image is-default">
                  <StaticImage
                    src="../../assets/img/pages/top/casestudy_img01.png"
                    alt={node.title}
                    placeholder="blurred"
                  />
                </div>
              )}
              <div className="resouses-articles-list__text">
                <span className="resouses-articles-list__name">{node.company_name}</span>
                <h3 className="resouses-articles-list__title">{node.title}</h3>
                {(node.type_category || node.uses_category || node["ai-services_category"]) && (
                  <ul className="resouses-articles-list__tag">
                    {node.type_category?.map((category) => (
                      <li key={category.id}>{category.name}</li>
                    ))}
                    {node.uses_category?.map((category) => (
                      <li key={category.id}>{category.name}</li>
                    ))}
                    {node["ai-services_category"]?.map((category) => (
                      <li key={category.id}>{category.name}</li>
                    ))}
                  </ul>
                )}
                <div className="resouses-articles-list__button">
                  <Button className="c-button01" label="詳しく見る" />
                </div>
              </div>
            </Link>
          </li>
        ))}
      </ol>

      {isLoading && (
        <div className="resouses__loader">
          <Loader />
        </div>
      )}

      {visibleCount < allArticles.length && !isLoading && (
        <div className="resouses__button">
          <Button className="c-button03 is-white" label="さらに導入事例を表示" onClick={loadMore} />
        </div>
      )}

    </>
  );
}
